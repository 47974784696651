import React, { useCallback, useEffect } from "react";
import { Loading, Card, Button } from "Components";
import { useGetAllCards } from "./useAllCardsApis";
import { useSearchContext } from "Context/SearchContext";
import "./style.css";

export const AllCards = () => {
  const { searchTerm } = useSearchContext();
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    refetch,
  } = useGetAllCards({
    limit: 20,
    page: 1,
    keywords: searchTerm || "",
  });

  const loadMore = useCallback(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  useEffect(() => {
    refetch();
  }, [searchTerm, refetch]);

  return (
    <div style={{ width: "90%" }}>
      <h1 className="main_header">All Products</h1>
      <div className="flex_r card_list">
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {data.pages.map((page, pageIndex) => (
              <React.Fragment key={pageIndex}>
                {page.items.map((product) => (
                  <Card data={product} key={product._id} />
                ))}
              </React.Fragment>
            ))}
          </>
        )}
        {/* {isLoading ? (
                    <Loading />
                ) : (
                    data?.items?.map((product) => <Card data={product} key={product._id} />)
                )} */}
      </div>
      <div className="flex_c align_items_center">
        {hasNextPage && (
          <Button
            name={isFetchingNextPage ? "Loading..." : "Load More"}
            onClick={loadMore}
            disabled={isFetchingNextPage}
          />
        )}
      </div>
    </div>
  );
};
